var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "start-stop-container", class: `chapter-${_vm.chapterNo}` },
    [
      _vm.position === "lesson"
        ? _c("div", { staticClass: "lesson-box" }, [
            _c(
              "span",
              {
                staticClass: "lesson_one",
                class: { lessononehover: _vm.lessononehover },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.activeClick.apply(null, arguments)
                  },
                  mouseenter: function ($event) {
                    return _vm.hoverImg(1, "hover")
                  },
                  mouseleave: function ($event) {
                    return _vm.hoverImg(1, "")
                  },
                },
              },
              [
                _c("AudioPlayer", {
                  attrs: { isPlay: _vm.isStartPlaying, audioUrl: _vm.songUrl },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "customlesson_num" }, [
              _c("div", { staticClass: "customlesson_two" }, [
                _c("span", { staticClass: "pinyin pinyin font-pinyin-large" }, [
                  _vm._v(_vm._s(_vm.pageInfo.pinyin)),
                ]),
              ]),
              _c("div", { staticClass: "customlesson_three" }, [
                _c(
                  "span",
                  {
                    staticClass: "txt-hz font-hanzi-large",
                    attrs: { id: "face" },
                  },
                  [_vm._v(_vm._s(_vm.pageInfo.hanzi))]
                ),
              ]),
              _c("div", { staticClass: "customlesson_four" }, [
                _c("span", { staticClass: "englishFont font-english-large" }, [
                  _vm._v(_vm._s(_vm.pageInfo.english)),
                ]),
              ]),
            ]),
          ])
        : _c("div", { staticClass: "begin-end-box" }, [
            _vm.position === "begin"
              ? _c("div", { staticClass: "customlesson_one" }, [
                  _c("div", { staticClass: "customlesson_one_img" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/05-Panda/panda-title-background.svg"),
                        alt: "",
                      },
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "lesson_one",
                        class: { lessononehovertwo: _vm.lessononehovertwo },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.activeClick.apply(null, arguments)
                          },
                          mouseenter: function ($event) {
                            return _vm.hoverImg(2, "hover")
                          },
                          mouseleave: function ($event) {
                            return _vm.hoverImg(2, "")
                          },
                        },
                      },
                      [
                        _c("AudioPlayer", {
                          attrs: {
                            isPlay: _vm.isStartPlaying,
                            audioUrl: _vm.songUrl,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "customlesson_num" }, [
                      _c("div", { staticClass: "customlesson_two" }, [
                        _c(
                          "span",
                          { staticClass: "font-face pinyin font-pinyin-large" },
                          [
                            _vm._v(_vm._s(_vm.pageInfo.pinyin) + " "),
                            _vm.pageInfo.vowelList
                              ? _c("span", { staticClass: "pinyin" }, [
                                  _vm._v(
                                    " | " + _vm._s(_vm.pageInfo.vowelList) + " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "customlesson_three" }, [
                        _c(
                          "span",
                          {
                            staticClass: "txt-hz font-hanzi-large",
                            attrs: { id: "face" },
                          },
                          [
                            _vm._v(_vm._s(_vm.pageInfo.hanzi) + " "),
                            _vm.pageInfo.vowelList
                              ? _c("span", { staticClass: "pinyin" }, [
                                  _vm._v(
                                    " | " + _vm._s(_vm.pageInfo.vowelList) + " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "customlesson_four" }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "txt-en englishFont font-english-large",
                          },
                          [
                            _vm._v(_vm._s(_vm.pageInfo.english) + " "),
                            _vm.pageInfo.vowelList
                              ? _c("span", { staticClass: "pinyin" }, [
                                  _vm._v(
                                    " | " + _vm._s(_vm.pageInfo.vowelList) + " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _c("div", { staticClass: "customlesson_twoimg" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/05-Panda/panda-zaijian-background.svg"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "customlesson_num" }, [
                    _c("div", { staticClass: "customlesson_two" }, [
                      _c("span", { staticClass: "font-pinyin-xlarge pinyin" }, [
                        _vm._v(_vm._s(_vm.pageInfo.pinyin)),
                      ]),
                    ]),
                    _c("div", { staticClass: "customlesson_three" }, [
                      _c("span", { staticClass: "font-hanzi-xlarge" }, [
                        _vm._v(_vm._s(_vm.pageInfo.hanzi)),
                      ]),
                    ]),
                  ]),
                ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }