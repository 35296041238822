var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "word-container" }, [
    !_vm.wordsObj.symbolType
      ? _c(
          "div",
          {
            staticClass: "word-box",
            class: { hover: _vm.isHover },
            on: {
              mouseenter: _vm.handleHover,
              mouseleave: _vm.handleNotHover,
              click: _vm.handlePlayWords,
            },
          },
          [
            _c("div", { staticClass: "pinyin font-pinyin-medium", class: {} }, [
              _vm._v(" " + _vm._s(_vm.wordsObj.pinyin) + " "),
            ]),
            _c("div", { staticClass: "hanzi font-hanzi-medium" }, [
              _vm._v(" " + _vm._s(_vm.wordsObj.hanzi) + " "),
            ]),
          ]
        )
      : _vm._e(),
    _vm.wordsObj.symbolType !== 8
      ? _c(
          "div",
          {
            staticClass: "symbol-box",
            class: {
              "type-1": _vm.wordsObj.symbolType === 1,
              "type-2": _vm.wordsObj.symbolType === 2,
              "type-3": _vm.wordsObj.symbolType === 3,
              "type-4": _vm.wordsObj.symbolType === 4,
              "type-5": _vm.wordsObj.symbolType === 5,
              "type-6": _vm.wordsObj.symbolType === 6,
              "type-7": _vm.wordsObj.symbolType === 7,
            },
          },
          [
            _c("div", { staticClass: "symbol" }, [
              _vm._v(" " + _vm._s(_vm.wordsObj.symbol) + " "),
            ]),
          ]
        )
      : _vm._e(),
    _vm.wordsObj.symbolType === 8
      ? _c(
          "div",
          {
            staticClass: "img-box",
            class: { hover: _vm.isHover },
            on: {
              mouseenter: _vm.handleHover,
              mouseleave: _vm.handleNotHover,
              click: _vm.handlePlayWords,
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "pinyin",
                class: { hiddleBlock: !_vm.wordsObj.isHavePinyin },
              },
              [_vm._v(" " + _vm._s(_vm.wordsObj.pinyin) + " ")]
            ),
            _c("div", { staticClass: "stroke-img" }, [
              _c("img", { attrs: { src: _vm.wordsObj.imgUrl, alt: "" } }),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }