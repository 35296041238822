
<template>
  <div class="syllabus chapter-syllabusone">
    <div class="syllabus-box">
      <img
        class="bg-img"
        src="@/assets/img/03-Backgrounds/background-syllabus.svg"
        alt=""
      />
      <div class="vowels-to-learn" v-if="vowelsToLearn">
        <span class="vowels-content">
          {{vowelsToLearn}}
        </span>
      </div>
      <div class="syllabus-content" v-if="isPageA" ref="syllabusBox">
        <!-- 我会认 -->
        <div
          class="i-can-item"
          v-for="(item, listIndex) in wordList"
          :key="listIndex + 'i-can'"
        >
          <div class="title font-lesson-subtitles">{{ item.title }}</div>
          <!-- not我会用 -->
          <div class="content-common" v-if="!item.isUse" :class="{'has-multiple-lines': item.content[0][0]}">
            <div
              class="word-item"
              v-for="(ele, index) in item.content"
              :key="index + '0'"
              :class="{'is-own-line': ele.fullWidth}"
            >
              <Word
                :wordsObj="ele"
                @hoverTHisWords="getWordsId(listIndex, index, undefined, true)"
                :pageType="1"
                @notHoverTHisWords="
                  getWordsId(listIndex, index, undefined, false)
                "
                @clickToPlayWords="handlePlay(listIndex, index, undefined)"
              />
            </div>
          </div>

          <!-- 我会用 -->
          <div class="content-use" v-else ref="syllabusBox">
            <div
              class="word-item"
              v-for="(ele, contentListIndex) in item.contentList"
              :key="contentListIndex + 'single'"
            >
              <div
                class="use-single"
                v-for="(element, index) in ele"
                :key="index + 'single-one'"
              >
                <Word
                  :wordsObj="element"
                  @hoverTHisWords="getWordsId(1, contentListIndex, index, true)"
                  :pageType="1"
                  @notHoverTHisWords="
                    getWordsId(1, contentListIndex, index, false)
                  "
                  @clickToPlayWords="handlePlay(1, contentListIndex, index)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="syllabus-content" v-else ref="syllabusBox">
        <div
          class="i-can-item"
          v-for="(item, listIndex) in wordList"
          :key="listIndex + 'i-can'"
        >
          <div class="title">{{ item.title }}</div>
          <!-- not我会用 -->
          <div class="content-common" v-if="!item.isReview">
            <div
              class="word-item"
              v-for="(ele, index) in item.content"
              :key="index + '0'"
            >
              <Word
                :wordsObj="ele"
                @hoverTHisWords="getWordsId(listIndex, index, undefined, true)"
                :pageType="0"
                @notHoverTHisWords="
                  getWordsId(listIndex, index, undefined, false)
                "
                @clickToPlayWords="handlePlay(listIndex, index, undefined)"
              />
            </div>
          </div>

          <!-- 我会用 -->
          <div class="content-use" v-else>
            <div
              class="word-item"
              v-for="(ele, contentListIndex) in item.contentList"
              :key="contentListIndex + 'single'"
            >
              <div
                class="use-single"
                v-for="(element, index) in ele"
                :key="index + 'single-one'"
              >
                <Word
                  :wordsObj="element"
                  @hoverTHisWords="getWordsId(0, contentListIndex, index, true)"
                  :pageType="0"
                  @notHoverTHisWords="
                    getWordsId(0, contentListIndex, index, false)
                  "
                  @clickToPlayWords="handlePlay(0, contentListIndex, index)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Word from "@/components/Course/CoursePage/Word";

export default {
  name: "Syllabus",
  props: {
    wordList: {
      type: Array,
      require: true,
    },
    isPageA: {
      type: Boolean,
      require: true,
    },
    vowelsToLearn: {
      type: String,
      require: false,
    },
  },
  components: {
    Word,
  },
  data() {
    return {
      isWordHover: true,
      distance: 0,
      contentHeight: null,
      syllabusBox: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.syllabusBox.addEventListener("wheel", (e) => {
        const data = {
          clickType: 20017,
          data: {
            value: {
              deltaY: e.deltaY,
            },
          },
          text: "测试SyllabusPage组件内scroll事件socket",
        };
        this.$bus.$emit("kids_webSocket_sendInfo", data);
      });
    });

    this.$bus.$on("scrollMouseX", (e) => {
      this.$nextTick(() => {
        this.handleMouseScroll(e);
      });
    });
    this.contentHeight = this.$refs.syllabusBox.getBoundingClientRect().height;
    this.syllabusBox = this.$refs.syllabusBox;
  },
  methods: {
    getWordsId(listIndex, index, useIndex, isHover) {
      // 获取hover元素的id
      const data = {
        clickType: 20004,
        data: {
          value: { listIndex, index, useIndex, isHover, isStudent: true },
        },
        text: "测试SyllabusPagePage组件内hover事件socket",
      };
      this.$bus.$emit("kids_webSocket_sendInfo", data);
    },
    // 滚动实现
    handleMouseScroll(e) {
      const speed = e.deltaY > 0 ? 40 : -40;
      let dis;
      this.distance += speed;
      if (this.distance < 0) {
        dis = 0;
        this.distance = 0;
      } else if (this.distance > this.contentHeight) {
        dis = this.contentHeight;
        this.distance = this.contentHeight;
      } else {
        dis = this.distance;
      }
      // setTimeout(() => {
      this.syllabusBox.scrollTop = dis;
      // }, 50);
    },
  },
};
</script>
<style scoped lang="scss">
@import "./index.scss";
</style>
