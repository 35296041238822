
<template>
  <div class="audio-player-container">
    <audio v-if="showAudioElement" :src="audioUrl" ref="audioBox"></audio>
    <img :src="audioImg" />
  </div>
</template>

<script>
export default {
  props: {
    isPlay: {
      type: Number,
      require: true,
    },
    audioUrl: {
      type: String,
      require: true,
    },
    showAudioElement: {
      type: Boolean,
      require: false,
      default: true,
    }
  },
  watch: {
    isPlay(value) {
      if (value) {
        this.play();
      }
    },
  },
  data() {
    return {
      audioImg: require("@/assets/img/06-Sound/sound-white.svg"),
    };
  },
  methods: {
    play() {
    if (!this.showAudioElement) return;
      const audio = new Audio(this.audioUrl);
      setTimeout(() => {
        audio.play();
      }, 150);
      this.audioImg = require("@/assets/img/06-Sound/sound-white-big.gif");
      setTimeout(() => {
        this.audioImg = require("@/assets/img/06-Sound/sound-white.svg");
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.audio-player-container {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
}
</style>