<template>
  <div class="word-container">
    <div
      class="word-box"
      v-if="!wordsObj.symbolType"
      :class="{ hover: isHover }"
      @mouseenter="handleHover"
      @mouseleave="handleNotHover"
      @click="handlePlayWords"
    >
      <div class="pinyin font-pinyin-medium" :class="{}">
        {{ wordsObj.pinyin }}
      </div>
      <div class="hanzi font-hanzi-medium">
        {{ wordsObj.hanzi }}
      </div>
    </div>
    <div
      class="symbol-box"
      :class="{
        'type-1': wordsObj.symbolType === 1,
        'type-2': wordsObj.symbolType === 2,
        'type-3': wordsObj.symbolType === 3,
        'type-4': wordsObj.symbolType === 4,
        'type-5': wordsObj.symbolType === 5,
        'type-6': wordsObj.symbolType === 6,
        'type-7': wordsObj.symbolType === 7,
      }"
      v-if="wordsObj.symbolType !== 8"
    >
      <div class="symbol">
        {{ wordsObj.symbol }}
      </div>
    </div>
    <div
      class="img-box"
      v-if="wordsObj.symbolType === 8"
      :class="{ hover: isHover }"
      @mouseenter="handleHover"
      @mouseleave="handleNotHover"
      @click="handlePlayWords"
    >
      <div class="pinyin" :class="{ hiddleBlock: !wordsObj.isHavePinyin }">
        {{ wordsObj.pinyin }}
      </div>
      <div class="stroke-img">
        <img :src="wordsObj.imgUrl" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // symbolType: 0word 1、 2： 3 “ 4！ 5…… 6。7”
  props: {
    wordsObj: {
      type: Object,
      require: true,
    },
    pageType: {
      //1 is lesson A  0 is lesson B
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      isHover: false,
    };
  },
  mounted() {
    const { group, line, index: wordIndex } = this.wordsObj;
    this.$bus.$on(
      "hoverSyllabusPage",
      ({ listIndex, index, useIndex, isHover, isStudent }) => {
        if (isStudent) {
          if (listIndex !== this.pageType) {
            if (group === listIndex && wordIndex === index) {
              this.isHover = isHover;
            }
          } else {
            if (
              group === listIndex &&
              line === index &&
              wordIndex === useIndex
            ) {
              this.isHover = isHover;
            }
          }
        }
      }
    );
    this.$bus.$on("playWords", ({ listIndex, index, useIndex }) => {
      if (listIndex !== this.pageType) {
        if (group === listIndex && wordIndex === index) {
          this.handlePlayWords();
        }
      } else {
        if (group === listIndex && line === index && wordIndex === useIndex) {
          this.handlePlayWords();
        }
      }
    });
  },

  beforeDestroy() {
    this.$bus.$off("hoverSyllabusPage");
    this.$bus.$off("playWords");
  },
  methods: {
    handlePlayWords() {
      // to do list
      if (this.$store.state.toolBar.isTeacherPoint) {
        this.$emit("hoverTHisWords");
        return;
      }
      this.$emit("clickToPlayWords");
    },
    handleHover() {
      this.isHover = true;
      this.$emit("hoverTHisWords");
    },
    handleNotHover() {
      this.isHover = false;
      this.$emit("notHoverTHisWords");
    },
  },
};
</script>

<style lang="scss" scoped>
.word-container {
  width: 100%;
  height: 100%;
  color: #224e96;

  .word-box {
    // background: #e0e9f7;
    padding: 2px 10px;
    border-radius: 10px;
    margin: 0 4px;
    margin-bottom: 10px;
    cursor: pointer;
    .pinyin {
      // font-family: "STHEITISCPINYIN-LIGHT";
      text-align: center;
      font-size: 1.1em !important;
      line-height: 30px !important;
      min-height: 30px;
      margin-bottom: -10px;
    }
    .hanzi {
      // font-size: 2em !important;
      text-align: center;
      white-space: nowrap;
    }
  }
  .hover {
    background: #e0e9f7;
  }
  .interhover {
    background: #e0e9f7;
  }
  .symbol-box {
    width: 100%;
    height: calc(100% - 10px);
    display: flex;
    justify-content: center;
    align-items: center;

    .symbol {
      font-size: 1.6em !important;
      z-index: 100;
    }
    // symbolType: 0word 1、 2： 3 “ 4！ 5…… 6。7”
  }
  .img-box {
    padding: 2px 10px;
    border-radius: 10px;
    margin: 0 4px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .pinyin {
      // font-family: "STHEITISCPINYIN-LIGHT";
      text-align: center;
      font-size: 1.1em !important;
      line-height: 30px !important;
      min-height: 30px;
      margin-bottom: -5px;
    }
    .hiddleBlock {
      opacity: 0;
    }
    .stroke-img {
      img {
        width: 100%;
        max-width: 40px;
      }
    }
  }
  .type-1 {
    align-items: flex-end;
    .symbol {
      max-width: 20px;
      margin-left: -10px;
    }
  }
  .type-2 {
    align-items: flex-end;
    .symbol {
      max-width: 2em;
      margin-left: -10px;
      margin-bottom: 5px;
    }
  }
  .type-3 {
    align-items: flex-end;
    .symbol {
      max-width: 20px;
      margin-bottom: 10px;
    }
  }
  .type-7 {
    align-items: flex-end;
    .symbol {
      max-width: 20px;
      margin-bottom: 10px;
    }
  }
  .type-4 {
    align-items: flex-end;
    .symbol {
      // font-size: 2em !important;
      margin-bottom: 2px;
    }
  }
  .type-5 {
    align-items: flex-end;
    .symbol {
      max-width: 50px;
      // margin-bottom: -5px;
    // max-width: 2em;
    margin-left: -10px;
    margin-bottom: 5px;
    }
  }

  .type-6 {
    align-items: flex-end;

    .symbol {
      max-width: 20px;
      margin-left: -10px;
    }
  }
}
</style>
