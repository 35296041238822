<template>
  <div class="start-stop-container" :class="`chapter-${chapterNo}`">
    <!-- lesson -->
    <div class="lesson-box" v-if="position === 'lesson'">
      <span
        class="lesson_one"
        @click.stop="activeClick"
        @mouseenter="hoverImg(1, 'hover')"
        @mouseleave="hoverImg(1, '')"
        :class="{ lessononehover: lessononehover }"
      >
        <AudioPlayer :isPlay="isStartPlaying" :audioUrl="songUrl" />
      </span>
      <div class="customlesson_num">
        <div class="customlesson_two">
          <span class="pinyin pinyin font-pinyin-large">{{
            pageInfo.pinyin
          }}</span>
        </div>
        <div class="customlesson_three">
          <span id="face" class="txt-hz font-hanzi-large">{{
            pageInfo.hanzi
          }}</span>
        </div>
        <div class="customlesson_four">
          <span class="englishFont font-english-large">{{
            pageInfo.english
          }}</span>
        </div>
      </div>
    </div>

    <!-- 再见 - 你好 -->

    <div class="begin-end-box" v-else>
      <div class="customlesson_one" v-if="position === 'begin'">
        <div class="customlesson_one_img">
          <img src="@/assets/img/05-Panda/panda-title-background.svg" alt="" />
          <span
            class="lesson_one"
            @click.stop="activeClick"
            @mouseenter="hoverImg(2, 'hover')"
            @mouseleave="hoverImg(2, '')"
            :class="{ lessononehovertwo: lessononehovertwo }"
          >
            <AudioPlayer :isPlay="isStartPlaying" :audioUrl="songUrl" />
          </span>
          <div class="customlesson_num">
            <div class="customlesson_two">
              <span class="font-face pinyin font-pinyin-large">{{
                pageInfo.pinyin
              }}
              <span class="pinyin" v-if="pageInfo.vowelList">
                | {{pageInfo.vowelList}}
              </span>
                </span>
            </div>
            <div class="customlesson_three">
              <span id="face" class="txt-hz font-hanzi-large">{{
                pageInfo.hanzi
              }}
              <span class="pinyin" v-if="pageInfo.vowelList">
                | {{pageInfo.vowelList}}
              </span>              
              </span>

            </div>
            <div class="customlesson_four">
              <span class="txt-en englishFont font-english-large">{{
                pageInfo.english
              }}
              <span class="pinyin" v-if="pageInfo.vowelList">
                | {{pageInfo.vowelList}}
              </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="customlesson_twoimg" v-else>
        <img src="@/assets/img/05-Panda/panda-zaijian-background.svg" alt="" />
        <div class="customlesson_num">
          <div class="customlesson_two">
            <span class="font-pinyin-xlarge pinyin">{{ pageInfo.pinyin }}</span>
          </div>
          <div class="customlesson_three">
            <span class="font-hanzi-xlarge">{{ pageInfo.hanzi }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AudioPlayer from "@/components/Course/GamePage/AudioPlayer";

export default {
  props: {
    position: {
      type: String,
      require: true,
    },
    pageInfo: {
      type: Object,
      require: true,
    },
    chapterNo: {
      type: Number,
      require: true,
      default: 1,
    },
  },
  components: {
    AudioPlayer,
  },
  data() {
    return {
      activeType: 0,
      clickNum: 0,
      lessononehover: false,
      lessononehovertwo: false,
      hoverFontNum: 0,
      songUrl: null,
      clickSocketInfo: {},
      isStartPlaying: null,
    };
  },
  watch: {
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20000,
          data: { value },
          text: "测试StartStop组件内点击事件socket",
        };
        this.$bus.$emit("kids_webSocket_sendInfo", data);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("clickStartStop", () => {
      this.activeClick("socket", true);
    });
    this.$bus.$on("hoverStartStop", (val) => {
      console.log("hover", val);
      this.hoverImg(val, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickStartStop");
    this.$bus.$off("hoverStartStop");
  },
  methods: {
    hoverImg(id, isFromSocket = false) {
      if (!isFromSocket) {
        this.hoverFontNum++;
      }
      this.hoverFontId = id;
      switch (id) {
        case 1:
          this.lessononehover = !this.lessononehover;
          break;
        case 2:
          this.lessononehovertwo = !this.lessononehovertwo;
          break;
      }
    },
    activeClick(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickSocketInfo = {
          value: Math.random(),
        };
      }
      this.isStartPlaying = Math.random();
      if (this.position !== "end") {
        if (this.pageInfo.sound != "") {
          this.songUrl = this.pageInfo.sound;
        }
      } 
    },
  },
};
</script>
<style scoped lang="scss">
.start-stop-container {
  width: 100%;
  height: 100%;
  border-radius: 56px;
  position: relative;
  background-image: url("../../../../assets/img/03-Backgrounds/level-1-background-color.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -moz-background-size: 100% 100%;
  background-color: #ffdbb8;

  &.chapter-5 {
    .txt-hz {
      font-size: 45px ;
    }
  }

  .lesson-box {
    padding: 30px;
    border-radius: 2em 0 0 2em;
    width: 35%;
    height: 36%;
    background-color: #cd4c3f;
    border-top-left-radius: 58px;
    border-bottom-left-radius: 58px;
    position: absolute;
    right: 0;
    top: 96px;
    display: flex;
    align-items: center;
    .lesson_one {
      position: absolute;
      top: -25px;
      left: 44%;
      width: 50px;
      height: 50px;
      background-color: #224e96;
      border-radius: 50%;
      text-align: center;
      .volume-button {
        cursor: pointer;
      }
      img {
        // width: 30px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        height: 99%;
      }
      audio {
      }
    }
    .lessononehover {
      background-color: #1b4589;
    }
    .lessononehovertwo {
      background-color: #1b4589 !important;
    }
    // .active{
    //   background-color:#1B4589;
    // }
    .customlesson_num {
      margin: auto;
      text-align: center;
      .customlesson_two {
        cursor: default;
        span {
          color: #ffffff;
        }

        .pinyin {
          font-family: "STHEITISCPINYIN-LIGHT";
        }
      }
      .customlesson_three {
        cursor: default;
        span {
          color: #ffffff;
        }

        @media screen and (max-width: 1300px) {
          .font-hanzi-large {
            font-size: 36px;
            line-height: 2;
          }
        }
      }
      .customlesson_four {
        // margin-top: 8%;
        cursor: default;

        span {
          color: #ffffff;
        }
      }
    }
  }
  .begin-end-box {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 56px;
    background-color: rgba(41, 40, 36, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    .customlesson_one {
      position: absolute;
      bottom: 0;
      width: 65%;
      .customlesson_one_img {
        width: 100%;
        position: relative;
        img {
          width: 100%;
          display: block;
        }
        .lesson_one {
          position: absolute;
          top: -25px;
          right: 15%;
          width: 60px;
          height: 60px;
          background-color: #224e96;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          text-align: center;
          .volume-button {
            cursor: pointer;
          }
          img {
            width: 100%;
          }
        }
      }
      .lessononehovertwo {
        background-color: #1b4589 !important;
      }
      // .active {
      //   background-color: #1b4589;
      // }
      .customlesson_num {
        position: absolute;
        top: 15%;
        text-align: center;
        width: 100%;
        
        @media screen and (max-width: 1300px) {
          .font-hanzi-large {
            font-size: 36px;
            line-height: 2;
          }
        }
        
        .customlesson_two {
          cursor: default;
          font-family: "STHeitiSCPinyin-light";

          .pinyin {
            @media screen and (max-width: 1500px) {
              // font-size: 1.5em;
            }
          }

          span {
            color: #224e96;
          }
        }
        .customlesson_three {
          cursor: default;
          width: 100%;
          span {
            color: #224e96;
          }
        }
        .customlesson_four {
          margin-top: 5%;
          cursor: default;
          span {
            color: #000000;
          }
          .englishFont {
            @media screen and (max-width: 1500px) {
              // font-size: 1.5em !important;
            }
          }
        }
      }
      .customlesson_num7 {
        position: absolute;
        top: 28%;
        text-align: center;
        width: 100%;
        .customlesson_two {
          cursor: default;
          font-family: "STHeitiSCPinyin-light";

          .pinyin {
            @media screen and (max-width: 1500px) {
              // font-size: 1.5em;
            }
          }

          span {
            color: #224e96;
          }
        }
        .customlesson_three {
          cursor: default;
          width: 100%;
          span {
            color: #224e96;
          }
        }
        .customlesson_four {
          margin-top: 5%;
          cursor: default;
          span {
            color: #000000;
          }
          .englishFont {
            @media screen and (max-width: 1500px) {
              // font-size: 1.5em !important;
            }
          }
        }
      }
    }
    .customlesson_twoimg {
      width: 50%;
      height: 40%;
      position: relative;
      img {
        width: 100%;
      }
      .customlesson_num {
        position: absolute;
        left: 17%;
        width: 100%;
        top: 25%;

        @media screen and (max-width: 2200px) {
          // external screeneft: 22%;
          top: 15%;
        }

        .customlesson_two {
          cursor: default;
          font-family: "STHeitiSCPinyin-light";
          span {
            color: #224e96;
          }
        }
        .customlesson_three {
          width: 100%;
          cursor: default;
          span {
            color: #224e96;
          }
          .txt-hz {
            font-size: 6em;
            line-height: 1.5em;

            @media screen and (max-width: 1500px) {
              // laptop
              line-height: 1.5em;
              font-size: 4em;
            }
            @media screen and (max-width: 1300px) {
              // ipad
              line-height: 1em;
            }
          }
        }
      }
    }
  }
}
.chapter-2 {
  background-image: url("../../../../assets/img/03-Backgrounds/level-1-chapter-2-background-color.svg");
}
.chapter-3 {
  background-image: url("../../../../assets/img/03-Backgrounds/level-1-chapter-3-background-color.svg");
}
.chapter-4 {
  background-image: url("../../../../assets/img/03-Backgrounds/level-1-chapter-4-background-color.svg");
}
.chapter-5 {
  background-image: url("../../../../assets/img/03-Backgrounds/level-1-chapter-5-background-color.svg");
}
.chapter-6 {
  background-image: url("../../../../assets/img/03-Backgrounds/level-1-chapter-6-background-color.svg");
}
.chapter-7 {
  background-image: url("../../../../assets/img/03-Backgrounds/level-1-chapter-7-background-color.svg");
}
.chapter-8 {
  background-image: url("../../../../assets/img/03-Backgrounds/level-1-chapter-8-background-color.svg");
}
</style>
